<template>
  <div class="content-wrap" style="background: #fff">
    <div class="wrap">
      <header class="pt-32">
        <img class="logo_img mb-16" src="@/assets/image/medal_logo_green.png" />
        <div class="fm-12 f-g40 mb-16">파트너 가입 신청</div>
        <div class="navBar mb-32">
          <ul>
            <li class="ml-16 fm-14">1.개인 정보 입력</li>
            <li class="ml-16 fm-14 nav-selected">2.분야 입력</li>
            <li class="ml-16 fm-14">3.가입동의</li>
            <li class="ml-16 fm-14">4.가입신청 완료</li>
          </ul>
        </div>
      </header>
      <div class="contentBox">
        <div class="title_name mb-16">
          <span class="fm-16">파트너 카테고리 </span>
          <div class="w-100 flex-space-between flex-align-center">
            <p class="fr-12 mt-8">파트너 가입자의 서비스분야를 설정해주세요.</p>
            <p class="essential">● 필수 입력 항목</p>
          </div>
        </div>
        <div class="mb-16 d-flex justify-content-center column">
          <div class="explanation-box fr-12 f-g60">
            ‘분야 카테고리’는 파트너 등록과정에서 검수를 위한 필수
            내용입니다.<br />
            구매자에게 노출되는 카테고리는 가입등록 후 별도로 설정
            가능합니다.<br />
            분야 카테고리 설정에 해당되는 경력사항을 설정해주시고 이와 관련된
            자격 및 경력 증명을 등록 해주세요.<br />
            결제항목 파일 등록은 매달 파트너 고객센터에서 제공한 ‘결제항목 설정
            가이드'를 참고하여 작성한 파일을 등록해주세요.
          </div>
        </div>
        <ul class="my_information">
          <li class="py-16 d-flex">
            <div style="width: 184px">
              <span class="category-title pt-8">분야 카테고리</span>
              <span class="essential pt-8 ml-8">●</span>
            </div>
            <div>
              <div class="d-flex mb-16">
                <select-box
                  :opt-nm="highCategoryList"
                  class="category-select"
                  v-model="params.highSpecialty"
                  @input="getLowCategory()"
                />
                <select-box
                  :opt-nm="lowCategoryList"
                  :disabled="!params.highSpecialty"
                  v-model="params.lowSpecialty"
                  class="category-select ml-8"
                />
              </div>
              <div class="select-item-title mt-8" v-if="params.highSpecialty">
                {{ getHighCategoryTitle }} > {{ getLowCategoryTitle }}
              </div>
              <div class="d-flex mt-12">
                <div>
                  <input
                    type="text"
                    style="width: 430px"
                    placeholder="선택가능한 목록에 서비스분야가 없다면 직접 기입해주세요."
                    v-model="params.orderSpecialty"
                  />
                  <p
                    class="error mt-12"
                    v-if="!checkCategoryValid"
                  >
                    서비스분야를 입력해주세요.
                  </p>
                </div>
              </div>
            </div>
          </li>
          <li class="py-16 d-flex mt-16">
            <div style="width: 184px">
              <span class="category-title pt-8">경력</span>
              <span class="essential pt-8 ml-8">●</span>
            </div>
            <div>
              <select-box
                :opt-nm="SELT_CAREER"
                class="category-select"
                style="width: 140px"
                v-model="params.career"
              />
              <p class="error mt-12" v-if="!params.career">
                활동경력을 입력해주세요.
              </p>
            </div>
          </li>
          <li class="py-16 d-flex mt-16">
            <div style="width: 184px">
              <span class="category-title pt-8">자격 증명</span>
              <span class="essential pt-8 ml-8">●</span>
            </div>
            <div>
              <div class="d-flex">
                <div>
                  <input
                    type="text"
                    placeholder="온라인 주소 (URL) 입력"
                    style="width: 320px"
                    v-model="params.proofUrl"
                  />
                  <p class="category-info" style="margin-top: 4px">
                    전문분야 활동 및 업태를 확인가능한 온라인 주소(URL) 입력
                  </p>
                  <p class="category-info">ex) 웹 사이트, SNS, 유투브, 블로그, 네이버지도 등</p>
                  <p class="error mt-12" v-if="!params.proofUrl">
                    자격증명 URL을 등록해주세요.
                  </p>
                </div>
              </div>
            </div>
          </li>
          <li class="py-16 d-flex mt-16">
            <div style="width: 184px">
              <span class="category-title pt-8">결제항목 파일</span>
              <span class="essential pt-8 ml-8">●</span>
            </div>
            <div class="d-flex">
              <div>
                <input
                  accept="*"
                  type="file"
                  class="d-none"
                  ref="fileRef"
                  @input="handleUpload"
                />
                <div class="d-flex">
                  <button-common
                    :size="SIZE_T"
                    :clr="CLR_G"
                    @click="$refs.fileRef.click()"
                    >파일 등록</button-common
                  >
                  <p class="fileName pt-8 ml-16">{{ fileName }}</p>
                </div>

                <p class="category-info" style="margin-top: 4px">
                  매달 파트너 고객센터에서 제공한 ‘결제항목 설정 가이드’를 활용하여 작성한 파일
                </p>
                <p class="error mt-12" v-if="!params.proofFile">
                  자격 증명 사항을 등록해주세요.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="next mb-80" style="margin-top: 62px">
      <button-common
        :size="SIZE_S"
        :clr="CLR_W"
        style="margin-right: 16px"
        @click="goBack()"
        >이전</button-common
      >
      <button-common
        :disabled="
          !checkCategoryValid ||
          !params.career ||
          !params.proofUrl ||
          !params.proofFile
        "
        :size="SIZE_S"
        :clr="CLR_B"
        @click="regCategory()"
        >다음</button-common
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "Category",
  components: {},
  data() {
    return {
      highCategoryList: [],
      lowCategoryList: [],
      fileName: "",
      params: {
        highSpecialty: "",
        lowSpecialty: "",
        orderSpecialty: "",
        career: "",
        proofUrl: "",
        proofFile: null,
      },
    };
  },
  computed: {
    getHighCategoryTitle() {
      const filter = this.highCategoryList.filter(
        (item) => item.value === this.params.highSpecialty
      );
      if (filter.length > 0) {
        return filter[0].title;
      }
    },
    getLowCategoryTitle() {
      const filter = this.lowCategoryList.filter(
        (item) => item.value === this.params.lowSpecialty
      );
      if (filter.length > 0) {
        return filter[0].title;
      }
    },
    checkCategoryValid() {
      return this.params.orderSpecialty.length > 0 || (this.params.highSpecialty && this.params.lowSpecialty)
    }
  },
  async mounted() {
    await this.getHighCategory();
  },
  methods: {
    handleUpload(e) {
      if (e.target.files[0]) {
        this.params.proofFile = e.target.files[0];
        this.fileName = e.target.files[0].name;
      }
    },
    async getHighCategory() {
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: "" },
      });
      if (rs.result === "SUC") {
        this.highCategoryList = rs.resultList.map((item) => {
          return { title: item.title, value: item.esntlId };
        });
        this.highCategoryList.unshift({ title: "상위분야 선택안함", value: "" });
      }
    },
    async getLowCategory() {
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: this.params.highSpecialty },
      });
      if (rs.result === "SUC") {
        this.lowCategoryList = rs.resultList.map((item) => {
          return { title: item.title, value: item.esntlId };
        });
        if (!this.params.highSpecialty) {
          this.params.lowSpecialty = ""
        }
        this.lowCategoryList.unshift({ title: "하위분야 선택안함", value: "" });
      }
    },
    async regCategory() {
      const formData = new FormData();
      formData.append("highSpecialty", this.params.highSpecialty);
      formData.append("lowSpecialty", this.params.lowSpecialty);
      formData.append("orderSpecialty", this.params.orderSpecialty);
      formData.append("career", this.params.career);
      formData.append("proofUrl", this.params.proofUrl);
      formData.append("proofFile", this.params.proofFile);
      const rs = await this.$axios({
        url: "/partner/reg/specialty",
        type: "MULTI",
        params: formData,
      });
      if (rs.result === "SUC") {
        this.goNextPage();
      }
    },
    goNextPage() {
      this.$router.push({
        path: `/sign/agree`,
        meta: { id: "0-1-7" },
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/join";
.li-wrap {
  padding: 16px 32px;
}
.error {
  color: #f1431d !important;
  font-size: 12px;
}
.confirm {
  color: #2ec8b5;
  font-size: 14px;
}
.fileName {
  color: #007eda !important;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
}
.essential {
  color: #ff0000 !important;
  font-size: 12px;
  font-weight: 500;
}
.category-title {
  font-size: 12px;
  font-weight: 500;
  color: #666666;
}
.category-select {
  width: 210px;
  height: 37px;
  text-align: left;
  padding-left: 16px;
}
.category-info {
  font-size: 12px;
  font-weight: 500;
  color: #828699;
}
.select-item-title {
  color: #007eda;
  font-size: 12px;
  font-weight: 500;
}
</style>
